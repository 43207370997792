import React from 'react';
import Loadable from 'react-loadable';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/zh_CN';

import {
  APP_NAME, APP_VERSION, APP_DESCRIPTION, APP_SHA,
} from 'services/constants';
import history from 'services/history';

const Main = Loadable({
  loading: () => null,
  loader: () => import('scenes/Main'),
});

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Router history={history}>
        <>
          <Helmet>
            <meta
              name="app-meta"
              content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
            />
          </Helmet>
          <Switch>
            <Route
              path="/"
              component={Main}
            />
          </Switch>
        </>
      </Router>
    </ConfigProvider>
  );
}

export default App;
